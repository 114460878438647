import clsx from 'clsx';
import { useRef } from 'react';

import { useMediaQuery } from '@marriott/shared/mi-helper-utils';
import { Types, baseVariables, constants } from '@marriott/mi-ui-library';

import {
  IMerchandisingHeroBannerButtons,
  IMerchandisingHeroBannerLocationCTA,
} from '../organisms/MerchandisingHeroBanner/MerchandisingHeroBanner.types';
import {
  LocationButton,
  PrimaryButton,
  SecondaryButton,
} from '../organisms/MerchandisingHeroBanner/MerchandisingHeroBanner.styles';

const { ImageAspectRatio, LinkTargetType } = Types;
const { NO_OPENER, NO_REFERER, TARGET_BLANK, COBRAND_MATH_HERO_CTA_CLASS } = constants;
const { INTERNAL, EXTERNAL } = LinkTargetType;
const { SIZE_3x1 } = ImageAspectRatio;

export const MerchandisingHeroBanner_Primary_Secondary_Buttons = (props: IMerchandisingHeroBannerButtons) => {
  const {
    callToActionText1,
    callToActionText2,
    isVerticalImage,
    isLightScrim,
    primaryopeninanewtab,
    secondaryopeninanewtab,
    cobrandmathhero,
    heroSize,
    destinationUrl1,
    destinationUrl2,
    opensInNewTab,
    metaData,
    trackingProperties,
    trackingPropertiesDynamic,
    custom_click_track_value,
  } = props;

  const MerchandisingHeroBannerRef = useRef(null);
  const isDesktopViewPort = useMediaQuery(baseVariables.mediaQuery.lg);

  return (
    <div className="row">
      <div className="col-12">
        <div
          className={clsx({
            'd-flex': !isVerticalImage,
          })}
        >
          {Boolean(callToActionText1) && (
            <PrimaryButton
              buttonCopy={callToActionText1}
              className={clsx('px-3', {
                'm-button-m': isDesktopViewPort,
                'm-button-s': !isDesktopViewPort,
                'm-button-primary': isLightScrim,
                'm-button-primary-inverse': !isLightScrim,
                'm-button-external': primaryopeninanewtab,
                [COBRAND_MATH_HERO_CTA_CLASS]: cobrandmathhero,
                'primaryButton-3x1': heroSize === SIZE_3x1,
              })}
              isLink={true}
              href={destinationUrl1}
              ref={MerchandisingHeroBannerRef}
              target={primaryopeninanewtab ? TARGET_BLANK : ''}
              rel={primaryopeninanewtab ? `${NO_OPENER} ${NO_REFERER}` : undefined}
              linkType={primaryopeninanewtab ? EXTERNAL : INTERNAL}
              externalArrow={primaryopeninanewtab}
              isTextBeforeChildren={true}
              compName={trackingProperties?.trackingContentPosition}
              linkAriaLabelOpenNewWindow={opensInNewTab}
              trackingProperties={{
                ...trackingPropertiesDynamic,
                trackingTag: metaData?.merTag || trackingProperties?.trackingTag1,
              }}
            >
              {Boolean(primaryopeninanewtab) && <span className="sr-only">{opensInNewTab}</span>}
            </PrimaryButton>
          )}

          {Boolean(!isVerticalImage && callToActionText2) && (
            <SecondaryButton
              buttonCopy={callToActionText2}
              className={clsx('px-3 no-impression', {
                'm-button-m': isDesktopViewPort,
                'm-button-s': !isDesktopViewPort,
                'm-button-secondary': isLightScrim,
                ' m-button-secondary-inverse': !isLightScrim,
                'm-button-external': secondaryopeninanewtab,
                [COBRAND_MATH_HERO_CTA_CLASS]: cobrandmathhero,
                'secondaryButton-3x1': heroSize === SIZE_3x1,
              })}
              isLink={true}
              href={destinationUrl2}
              target={secondaryopeninanewtab ? TARGET_BLANK : ''}
              rel={secondaryopeninanewtab ? `${NO_OPENER} ${NO_REFERER}` : undefined}
              linkType={secondaryopeninanewtab ? EXTERNAL : INTERNAL}
              externalArrow={secondaryopeninanewtab}
              isTextBeforeChildren={true}
              compName={trackingProperties?.trackingContentPosition}
              linkAriaLabelOpenNewWindow={opensInNewTab}
              trackingProperties={{
                ...trackingPropertiesDynamic,
                trackingTag: trackingProperties?.trackingTag2,
              }}
              custom_click_track_value={custom_click_track_value}
            >
              {Boolean(secondaryopeninanewtab) && <span className="sr-only">{opensInNewTab}</span>}
            </SecondaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

export const LocationCTA = (props: IMerchandisingHeroBannerLocationCTA) => {
  const {
    caption,
    captionUrl,
    hideLocationCtaMobile,
    locationopeninanewtab,
    custom_click_track_value,
    opensInNewTab,
    compName,
  } = props;
  return (
    <LocationButton
      buttonCopy={caption}
      className={clsx('t-label-xs ml-3 ml-md-0', { 'd-none d-md-block': hideLocationCtaMobile })}
      isLink={true}
      href={captionUrl}
      target={locationopeninanewtab ? TARGET_BLANK : ''}
      rel={locationopeninanewtab ? `${NO_OPENER} ${NO_REFERER}` : undefined}
      linkType={locationopeninanewtab ? EXTERNAL : INTERNAL}
      externalArrow={locationopeninanewtab}
      isTextBeforeChildren={false}
      compName={compName}
      linkAriaLabelOpenNewWindow={opensInNewTab}
      custom_click_track_value={custom_click_track_value}
    >
      <span className="icon-location"></span>
      {Boolean(locationopeninanewtab) && <span className="sr-only">{opensInNewTab}</span>}
    </LocationButton>
  );
};

import clsx from 'clsx';

import { Types, Text, Icon } from '@marriott/mi-ui-library';
import { convertToCommaSeparatedNumber } from '@marriott/shared/mi-helper-utils';
import { IMerchandisingHeroBannerVariations } from '../organisms/MerchandisingHeroBanner/MerchandisingHeroBanner.types';
import { CoBrandWrapper } from '../organisms/MerchandisingHeroBanner/MerchandisingHeroBanner.styles';

export const MerchandisingHeroBannerVariations = (props: IMerchandisingHeroBannerVariations) => {
  const { dataLayer, mathLabel1, mathLabel2, mathLabel3, mathValue, mathType, isLightScrim } = props;
  const { size, tags } = Types;
  const balancePoints = parseInt(`${dataLayer?.['mr_prof_points_balance'] ?? '0'}`, 10);
  const bonusPoint = mathValue ? parseInt(mathValue.replace(/,/g, ''), 10) : 0;
  const totalPoints: number = balancePoints + bonusPoint;

  const cobrandLabelClassName = clsx(
    isLightScrim ? 't-overline-normal t-color-black' : 't-overline-inverse-normal',
    'hb__cobrand-label'
  );
  const cobrandBalanceClassName = clsx(
    'hb__cobrand-balance-points',
    isLightScrim ? 't-overline-normal t-color-black' : 't-overline-inverse-normal'
  );
  const cobrandBonusClassName = clsx(
    'hb__cobrand-bonus-points',
    isLightScrim ? 't-overline-normal t-color-black' : 't-overline-inverse-normal'
  );
  const cobrandTotalClassName = clsx(
    'hb__cobrand-total-points',
    isLightScrim ? 't-overline-normal t-color-black' : 't-overline-inverse-normal'
  );

  const isPointsVariation = mathType?.toLowerCase() === 'points';
  const isNightsVariation = mathType?.toLowerCase() === 'nights';
  const yourBalance = parseInt(`${dataLayer?.['mr_prof_nights_booked_this_year'] ?? '0'}`, 10);
  const totalBalance = yourBalance + bonusPoint;

  return (
    <CoBrandWrapper className="row">
      <div className={clsx('col-lg-7 hb__cobrand', { 'nightsVariation ': isNightsVariation })}>
        <div className="hb__cobrand-balance">
          <Text
            copyText={mathLabel1 ?? ''}
            customClass={cobrandLabelClassName}
            fontSize={size.small}
            element={tags.div}
          />
          <div>
            <Text
              copyText={
                isPointsVariation
                  ? convertToCommaSeparatedNumber(balancePoints)
                  : convertToCommaSeparatedNumber(yourBalance)
              }
              customClass={cobrandBalanceClassName}
              fontSize={size.small}
              element={tags.div}
            />
          </div>
        </div>
        <Icon iconClass="icon-plus"></Icon>
        <div className="hb__cobrand-bonus">
          <Text
            copyText={mathLabel2 ?? ''}
            customClass={cobrandLabelClassName}
            fontSize={size.small}
            element={tags.div}
          />
          <div>
            <Text
              copyText={convertToCommaSeparatedNumber(bonusPoint) ?? '0'}
              customClass={cobrandBonusClassName}
              fontSize={size.small}
              element={tags.div}
            />
          </div>
        </div>
        <div
          className={clsx('equal-icon', {
            'd-sm-flex d-none': isPointsVariation,
            'd-none d-md-flex': isNightsVariation,
          })}
        >
          =
        </div>
        <div
          className={clsx('hb__cobrand-total', {
            // note: this will work only on small screens, becoz for mobile & desktop display set to block in css file
            'flex-column-reverse align-items-start': isNightsVariation,
          })}
        >
          <Text
            copyText={mathLabel3 ?? ''}
            customClass={cobrandLabelClassName}
            fontSize={size.small}
            element={tags.div}
          />
          <div className="hb__cobrand-total__points-wrapper">
            <Text
              copyText={
                isPointsVariation
                  ? convertToCommaSeparatedNumber(totalPoints)
                  : convertToCommaSeparatedNumber(totalBalance)
              }
              customClass={cobrandTotalClassName}
              fontSize={size.small}
              element={tags.div}
            />
          </div>
        </div>
      </div>
    </CoBrandWrapper>
  );
};

import clsx from 'clsx';
import { Heading, Text, Types } from '@marriott/mi-ui-library';
import { IMerchandisingHeroBannerContent } from '../organisms/MerchandisingHeroBanner/MerchandisingHeroBanner.types';
const { HeaderTextStyle, headingType, size, tags, ImageAspectRatio, AssetVariation } = Types;
const { H1, H3, H4, H5, H6, WITHOUT_CO_BRAND } = HeaderTextStyle;
const { SIZE_16x9, SIZE_2X1 } = ImageAspectRatio;

const getElement = (headertextstyle: string) => {
  switch (headertextstyle) {
    case H1:
      return tags.h1;
    case H3:
      return tags.h3;
    case H4:
      return tags.h4;
    case H5:
      return tags.h5;
    case H6:
      return tags.h6;
    default:
      return tags.h2;
  }
};

/**
 *
 * @param props
 * @returns eyeBrowText, Header & description on conditional basis
 */
export const MerchandisingHeroBannerContent = (props: IMerchandisingHeroBannerContent) => {
  const {
    headertextstyle,
    headerMedium,
    descriptionLong,
    isVerticalImage,
    heroSize,
    isLightScrim,
    assetsVariation,
    logo,
    font_icon,
    cobrandmathhero,
    eyebrowText,
    copyBlockWidthContainerClass,
  } = props;

  const headingTextClassName = clsx(
    'hb__heading',
    isVerticalImage ? 'vertical__image__hero-' + heroSize?.replace(':', 'x') : '',
    isLightScrim ? '' : 't-color-inverse',
    heroSize === SIZE_16x9 || heroSize === SIZE_2X1 ? '' : ' mb-0',
    heroSize === SIZE_16x9 && headertextstyle === WITHOUT_CO_BRAND && !isVerticalImage ? ' hb__heading-cobrand' : '',
    (assetsVariation === AssetVariation.IMAGE && logo?.imageSrc && !cobrandmathhero) ||
      (assetsVariation === AssetVariation.FONT_ICON && font_icon && !cobrandmathhero)
      ? ' hb__heading__assetsVariation mb-lg-2'
      : '',
    !isVerticalImage && cobrandmathhero ? ' hb__heading__cobrandMathHero mb-lg-3' : ''
  );

  const subHeadingTextClassName = clsx(
    'hb__subheading mb-0',
    isVerticalImage ? 'vertical__image__hero-' + heroSize?.replace(':', 'x') : '',
    isLightScrim ? '' : 't-color-inverse',
    heroSize === SIZE_16x9 || heroSize === SIZE_2X1 ? '' : ' mt-1 mb-0',
    (assetsVariation === AssetVariation.IMAGE && logo?.imageSrc && !cobrandmathhero) ||
      (assetsVariation === AssetVariation.FONT_ICON && font_icon && !cobrandmathhero)
      ? ' hb__subheading__assetsVariation '
      : '',
    !isVerticalImage && cobrandmathhero ? ' hb__subheading__cobrandMathHero ' : ''
  );

  return (
    <>
      {Boolean(!isVerticalImage && eyebrowText && !logo?.imageSrc) && (
        <div className="row">
          <div className={copyBlockWidthContainerClass}>
            <Text
              copyText={eyebrowText}
              fontSize={size.extraSmall}
              customClass={clsx('mb-1 mb-lg-2', {
                't-overline-normal t-color-black': isLightScrim,
                't-overline-inverse-normal': !isLightScrim,
              })}
              element={tags.div}
            />
          </div>
        </div>
      )}
      {Boolean(headerMedium) && (
        <div className="row">
          <div className={clsx(copyBlockWidthContainerClass)}>
            <Heading
              variation={headingType.title}
              element={getElement(headertextstyle)}
              titleText={headerMedium}
              customClass={headingTextClassName}
            />
          </div>
        </div>
      )}
      {Boolean(descriptionLong) && (
        <div className="row">
          <div className={clsx(copyBlockWidthContainerClass)}>
            <Heading
              variation={headingType.subtitle}
              element={tags.paragraph}
              fontSize={size.medium}
              titleText={descriptionLong}
              customClass={subHeadingTextClassName}
            />
          </div>
        </div>
      )}
    </>
  );
};

import { Types, renditions } from '@marriott/mi-ui-library';

interface ImageSource {
  altText: string;
  assetPath: string;
  dynamic: boolean;
  damPath: string;
  renditions: Array<{
    renditionPath: string;
    mediaValue: string;
    dynamic: boolean;
    damPath: string;
    mediaQuery: string;
    width: number;
    height: number;
  }>;
}

export interface logo {
  imageSrc: string;
  altText: string;
}

export interface MerchandisingHeroBannerProps {
  image: renditions;
  location?: string;
  description?: string;
  assetsVariation?: Types.AssetVariation;
  logo?: logo;
  imgAltText?: string;
  font_icon?: string;
  fontIconAltText?: string;
  fileReferenceImageVertical?: string;
  verticalImageAltText?: string;
  imageURLDesktop?: string;
  backgroundimagealttext?: string;
  imageURLMobile?: string;
  eyebrowText?: string;
  headertextstyle: Types.HeaderTextStyle;
  headerMedium?: string;
  descriptionLong?: string;
  callToActionText1?: string;
  destinationUrl1?: string;
  primaryopeninanewtab?: boolean;
  callToActionText2?: string;
  destinationUrl2?: string;
  secondaryopeninanewtab?: boolean;
  caption?: string;
  captionUrl?: string;
  locationopeninanewtab?: boolean;
  hideLocationCtaMobile?: boolean;
  horizontalgradient?: boolean;
  verticalgradient?: boolean;
  aspectRatioMobileVideo?: string;
  accountId?: string;
  playerId?: string;
  playerDomain?: string;
  cobrandmathhero?: boolean;
  mathLabel1?: string;
  mathLabel2?: string;
  mathLabel3?: string;
  mathValue?: string;
  videoaccountId?: string;
  enableCardImage?: boolean;
  enableMaskingOnDesktop?: boolean;
  enableVerticalCardImage?: boolean;
  enableAutoPlay?: boolean;
  enableMute?: boolean;
  enableVideoLoop?: boolean;
  heroSize?: Types.ImageAspectRatio;
  enableContentStyle?: boolean;
  imageLoading?: 'eager' | 'lazy';
  videoEnabledDesktop?: boolean;
  videoEnabledMobile?: boolean;
  appliedCssClassNames?: string;
  enableTarget?: boolean;
  trackingProperties?: TrackingProps;
  opensInNewTab?: string;
  videoIdDesktop?: string;
  videoIdMobile?: string;
  fileReferenceVideoCoverImage?: string;
  backgroundMedia?: ImageSource;
  componentId?: string;
  copyBlockWidth?: Types.HeaderCopyWidth;
  mboxParameter?: string;
  isDCAEnabled?: boolean;
  offerCode?: string;
}

export interface TrackingProps {
  atCCeVar48?: string;
  trackingDescription?: string;
  clickTrack?: boolean;
  impressionTrack?: boolean;
  trackingTag2?: string;
  trackingTag1?: string;
  merchandisingCategory?: string;
  trackingContentPosition?: string;
  impressionCount?: boolean;
  trackingOfferType?: string;
  countryCode?: string;
  issuerCode?: string;
  rpcCode?: string;
  offerCode?: string;
  cell?: string;
  prequal?: string;
  prequalSpids?: string;
}
//TODO:Move these to global styles
export const MERCHANDISING_HEROBANNER_GRADIENTS = {
  EXTRATHIN: {
    md: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 34.9%, rgba(0,0,0,0.54) 67.94%, rgba(0,0,0,0.62) 100%)`,
    xl: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37.37%, rgba(0,0,0,0.54) 68.33%, rgba(0,0,0,0.62) 100%)`,
  },
  THIN: {
    md: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 26.52%, rgba(0,0,0,0.54) 59.73%, rgba(0,0,0,0.62) 100%)`,
    xl: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 30.78%, rgba(0,0,0,0.54) 63.78%, rgba(0,0,0,0.62) 100%);`,
  },
  DEFAULT: {
    md: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 18.39%, rgba(0,0,0,0.54) 51.39%, rgba(0,0,0,0.62) 100%);`,
    xl: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 24.19%, rgba(0,0,0,0.54) 55.22%, rgba(0,0,0,0.62) 100%);`,
  },
  WIDE: {
    md: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 9.92%, rgba(0,0,0,0.54) 43.12%, rgba(0,0,0,0.62) 100%);`,
    xl: `linear-gradient(270.21deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 17.55%, rgba(0,0,0,0.54) 50.59%, rgba(0,0,0,0.62) 100%);`,
  },
};

export interface IMetaData {
  campaignId: string;
  cell: string;
  countryCode: string;
  endDate: string;
  guidSecondary: null;
  issuerCode: string;
  loyaltyBar: boolean;
  mathOffer: boolean;
  merTag: null;
  offerCode: string;
  optionalFields: null;
  prequal: boolean;
  rpcCode: string;
  spid: string;
  startDate: string;
  theme: string;
  title: string;
  type: string;
}

export interface ITargetContent {
  callToActionText1: string;
  callToActionText2: string;
  destinationUrl1: string;
  destinationUrl2: string;
  mathLabel1: string;
  mathLabel2: string;
  mathLabel3: string;
  mathValue: string;
  mathType: string;
  metaData: IMetaData;
  mboxName: string;
  isTargetContentLoaded: boolean;
  isTargetContentError: boolean;
}

export interface IMerchandisingHeroBannerHeader {
  headerMedium: string;
  headertextstyle: Types.HeaderTextStyle;
}

export interface IMerchandisingHeroBannerDescription {
  descriptionLong: string;
}

export interface IMerchandisingHeroBannerVariations
  extends Pick<ITargetContent, 'mathLabel1' | 'mathLabel2' | 'mathLabel3' | 'mathType' | 'mathValue'> {
  dataLayer: Record<string, unknown>;
  isLightScrim: boolean;
}

export interface IMerchandisingHeroBannerButtons
  extends Pick<
      ITargetContent,
      | 'callToActionText1'
      | 'callToActionText2'
      | 'destinationUrl1'
      | 'destinationUrl2'
      | 'metaData'
      | 'isTargetContentError'
    >,
    Pick<IMerchandisingHeroBannerVariations, 'isLightScrim'> {
  primaryopeninanewtab: boolean;
  secondaryopeninanewtab: boolean;
  cobrandmathhero: boolean;
  heroSize: Types.ImageAspectRatio;
  opensInNewTab: string;
  trackingProperties: TrackingProps;
  isVerticalImage: boolean;
  custom_click_track_value: string;
  trackingPropertiesDynamic: TrackingPropsInterface;
}

export interface IMerchandisingHeroBannerLocationCTA
  extends Pick<IMerchandisingHeroBannerButtons, 'custom_click_track_value' | 'opensInNewTab'> {
  locationopeninanewtab: boolean;
  caption: string;
  hideLocationCtaMobile: boolean;
  captionUrl: string;
  compName: string;
}

export interface IMerchandisingHeroBannerContent
  extends IMerchandisingHeroBannerHeader,
    IMerchandisingHeroBannerDescription,
    Pick<IMerchandisingHeroBannerButtons, 'heroSize' | 'isLightScrim' | 'isVerticalImage' | 'cobrandmathhero'> {
  assetsVariation: Types.AssetVariation;
  logo: logo;
  font_icon: string;
  eyebrowText: string;
  copyBlockWidthContainerClass: string;
}

export interface IMerchandisingHeroBannerVideo {
  aspectRatioMobileVideo?: string;
  componentId: string;
  enableAutoPlay?: boolean;
  enableMute?: boolean;
  enableVideoLoop?: boolean;
  fileReferenceVideoCoverImage: string;
  fontIconAltText: string;
  playerDomain: string;
  playerId: string;
  verticalImageAltText: string;
  videoEnabledDesktop?: boolean;
  videoEnabledMobile?: boolean;
  videoIdDesktop?: string;
  videoIdMobile?: string;
  videoaccountId: string;
}

export interface IMerchandisingHeroBanner
  extends ITargetContent,
    IMerchandisingHeroBannerContent,
    IMerchandisingHeroBannerButtons,
    IMerchandisingHeroBannerLocationCTA,
    IMerchandisingHeroBannerVariations,
    IMerchandisingHeroBannerVideo {
  mboxParameter: string;
  fileReferenceImageVertical: string;
  appliedCssClassNames: string;
  copyBlockWidth: Types.HeaderCopyWidth;
  enableMaskingOnDesktop: boolean;
  imageLoading: 'eager' | 'lazy';
  image: renditions;
  enableTarget: boolean;
}

export interface TrackingPropsInterface {
  trackingContentPosition?: string;
  trackingOfferType?: string;
  atCCeVar48?: string;
  trackingDescription?: string;
  trackingTag?: string;
  clickTrack?: boolean;
  impressionTrack?: boolean;
  impressionCount?: boolean;
  impressionEventType?: string;
  merchandisingCategory?: string;
  additionalTrackingVariables?: string;
  location?: string;
  payloadType?: string;
  compName?: string;
  enableScrollingBehavior?: boolean;
  isAuth?: boolean;
  clickTrackValSeparator?: string;
  cell?: string;
  spid?: string;
  rpcCode?: string;
  offerCode?: string;
  isDCAEnabled?: boolean;
  type?: string;
}
